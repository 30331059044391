import { Modal } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { CrossSvg } from '../../assets/icons/CrossSvg';
import theme from '../../assets/theme';
import { zIndexes } from '../../utils/zIndexes';

const BaseModal = ({
  visible,
  onCancel,
  children,
  centered,
  title,
  width,
  maskStyle,
  zIndex,
  style,
  maskClosable,
  transparentBackground,
  hideCancel = false,
}: {
  title?: string;
  visible: boolean;
  centered?: boolean;
  onCancel?: () => void;
  hideCancel?: boolean;
  maskClosable?: boolean;
  children?: React.ReactNode;
  width?: string;
  maskStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  zIndex?: number;
  transparentBackground?: boolean;
}) => {
  const closableMask = maskClosable !== undefined ? maskClosable : !!onCancel;
  return (
    <ModalStyled
      $transparentBackground={transparentBackground}
      visible={visible}
      onCancel={onCancel}
      maskClosable={closableMask}
      footer={null}
      destroyOnClose
      centered={centered}
      width={width}
      closable={false}
      bodyStyle={{ padding: 0 }}
      maskStyle={maskStyle}
      onOk={onCancel}
      zIndex={zIndex}
      style={style}
    >
      <ModalContainer
        $titleLength={title?.length}
        $transparentBackground={transparentBackground}
        onClick={() => transparentBackground && onCancel && onCancel()}
      >
        {onCancel && !hideCancel && (
          <CrossIconWrapper
            onClick={onCancel}
            style={transparentBackground ? { backgroundColor: 'white' } : {}}
          >
            <CrossSvg />
          </CrossIconWrapper>
        )}
        <div style={{ width: '100%' }}>
          {title && (
            <h1
              style={{
                width: '100%',
                paddingRight: 22,
                textAlign: 'left',
                minHeight: '30px',
                marginBottom: 5,
              }}
            >
              {title}
            </h1>
          )}
          {children}
        </div>
      </ModalContainer>
    </ModalStyled>
  );
};

export default BaseModal;

const ModalStyled = styled(Modal)<{ $transparentBackground?: boolean }>`
  .ant-modal-content {
    border-radius: 10px;
    box-shadow: none;
    ${({ $transparentBackground }) =>
      $transparentBackground && 'background-color: transparent'};
  }
  .ant-modal-body {
    box-shadow: none;
    border-radius: 10px;
    background-color: ${({ $transparentBackground }) =>
      $transparentBackground ? 'transparent' : theme.colors.white};
  }
`;

export const ModalContainer = styled.div<{
  $titleLength?: number;
  $transparentBackground?: boolean;
}>`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ $transparentBackground }) =>
    $transparentBackground ? 'transparent' : theme.colors.background};

  border-radius: 10px;
  h1 {
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    font-size: ${({ $titleLength }) =>
      $titleLength && $titleLength < 8
        ? `${56 - $titleLength * 4}px`
        : '22px'}; // Making smilies and shorter word bigger
  }

  @media (max-width: 900px) {
    h1 {
      font-size: ${({ $titleLength }) =>
        $titleLength && $titleLength < 8
          ? `${42 - $titleLength * 3}px`
          : '18px'}; // Making smilies and shorter word bigger
    }
  }
`;

export const CrossIconWrapper = styled.div`
  color: ${theme.colors.black};
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 20px;
    width: 20px;
  }
  cursor: pointer;
  border-radius: 50px;
  padding: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.3);
  }
  top: 20px;
  right: 20px;
  position: absolute;
  z-index: ${zIndexes('BASE_MODAL_CROSS_ICON')};
`;
