import React from 'react';

const Logo = () => (
  <svg
    width="857"
    height="241"
    viewBox="0 0 857 241"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M215.909 152.546C231.667 152.546 242 140.403 242 122.323V69.8869C242.193 65.8035 243.951 61.9512 246.909 59.1296C249.866 56.3079 253.797 54.7337 257.885 54.7337C261.973 54.7337 265.904 56.3079 268.862 59.1296C271.82 61.9512 273.578 65.8035 273.77 69.8869V124.663C273.77 159.274 250.782 182.004 215.909 182.004C181.036 182.004 158.052 159.274 158.052 124.663V69.8869C158.052 65.7081 159.712 61.7005 162.667 58.7456C165.621 55.7908 169.629 54.1309 173.808 54.1309C177.987 54.1309 181.994 55.7908 184.949 58.7456C187.904 61.7005 189.564 65.7081 189.564 69.8869V122.323C189.564 140.403 200.155 152.546 215.909 152.546Z"
      fill="currentColor"
    />
    <path
      d="M382.244 68.5966C382.337 70.6331 381.995 72.6658 381.242 74.5602C380.489 76.4545 379.342 78.1671 377.876 79.5841C376.411 81.0011 374.661 82.0904 372.742 82.7794C370.823 83.4684 368.78 83.7414 366.748 83.5805C361.84 83.5805 356.415 82.029 349.185 82.029C337.816 82.029 330.327 90.5529 330.327 103.727V163.652C330.327 167.831 328.668 171.839 325.713 174.793C322.758 177.748 318.75 179.408 314.571 179.408C310.393 179.408 306.385 177.748 303.43 174.793C300.475 171.839 298.815 167.831 298.815 163.652V69.8865C298.829 65.8587 300.378 61.9878 303.149 59.0638C305.919 56.1399 309.7 54.3836 313.722 54.1531C317.743 53.9227 321.7 55.2355 324.786 57.8238C327.872 60.4121 329.854 64.0808 330.327 68.0807C333.684 62.9957 338.249 58.8233 343.616 55.938C348.982 53.0526 354.98 51.5444 361.073 51.5488C371.395 51.5524 382.244 56.9735 382.244 68.5966Z"
      fill="currentColor"
    />
    <path
      d="M410.116 185.224C407.971 185.278 405.838 184.883 403.855 184.065C401.871 183.247 400.08 182.023 398.597 180.472C397.114 178.921 395.971 177.078 395.242 175.06C394.512 173.041 394.213 170.893 394.362 168.753V70.7213C394.266 68.5926 394.603 66.4665 395.352 64.4716C396.1 62.4766 397.246 60.6541 398.718 59.114C400.191 57.5738 401.96 56.348 403.919 55.5106C405.879 54.6731 407.987 54.2412 410.118 54.2412C412.249 54.2412 414.358 54.6731 416.317 55.5106C418.276 56.348 420.046 57.5738 421.518 59.114C422.991 60.6541 424.136 62.4766 424.884 64.4716C425.633 66.4665 425.97 68.5926 425.874 70.7213V168.753C426.024 170.893 425.724 173.042 424.994 175.06C424.265 177.079 423.122 178.923 421.638 180.473C420.155 182.024 418.363 183.248 416.379 184.066C414.395 184.884 412.262 185.279 410.116 185.224V185.224Z"
      fill="currentColor"
    />
    <path
      d="M576.811 119.109C576.811 159.346 549.953 187.162 517.402 187.162C510.127 187.319 502.915 185.791 496.327 182.699C489.74 179.607 483.957 175.034 479.43 169.337V223.349C479.532 225.498 479.196 227.646 478.443 229.661C477.691 231.677 476.537 233.519 475.052 235.075C473.567 236.632 471.782 237.871 469.804 238.718C467.826 239.565 465.697 240.001 463.545 240.001C461.394 240.001 459.265 239.565 457.287 238.718C455.309 237.871 453.524 236.632 452.039 235.075C450.553 233.519 449.4 231.677 448.647 229.661C447.895 227.646 447.559 225.498 447.66 223.349V69.9767C447.521 65.8481 448.991 61.8268 451.76 58.7613C454.529 55.6958 458.381 53.8257 462.502 53.5456C466.623 53.2655 470.692 54.5972 473.851 57.2598C477.009 59.9225 479.01 63.7079 479.43 67.8174C484.011 62.2364 489.825 57.7951 496.415 54.843C503.004 51.8909 510.188 50.5085 517.402 50.805C551.498 50.7873 576.811 80.2214 576.811 119.109ZM545.038 119.378C545.038 98.3153 531.351 81.5644 512.236 81.5644C493.379 81.5644 479.43 98.3082 479.43 119.378C479.43 139.903 493.379 156.374 512.236 156.374C531.351 156.374 545.038 139.903 545.038 119.378Z"
      fill="currentColor"
    />
    <path
      d="M652.739 50.7856C665.682 50.6054 678.386 54.2806 689.234 61.3432C700.082 68.4058 708.584 78.5366 713.656 90.4459C718.728 102.355 720.143 115.504 717.718 128.22C715.294 140.936 709.141 152.642 700.043 161.85C690.945 171.058 679.313 177.35 666.627 179.926C653.941 182.502 640.776 181.245 628.807 176.316C616.838 171.386 606.606 163.007 599.414 152.244C592.222 141.481 588.396 128.822 588.421 115.877C588.32 98.7167 595.039 82.2189 607.1 70.0122C619.162 57.8054 635.578 50.8893 652.739 50.7856ZM652.997 80.2339C633.882 80.2339 619.933 95.9881 619.933 115.877C619.933 135.766 633.882 151.782 652.997 151.782C671.854 151.782 685.544 135.766 685.544 115.877C685.544 95.9881 671.854 80.2339 652.997 80.2339V80.2339Z"
      fill="currentColor"
    />
    <path
      d="M856.268 15.1534V162.642C856.37 164.728 856.06 166.814 855.356 168.78C854.652 170.746 853.567 172.554 852.164 174.102C850.761 175.649 849.067 176.904 847.179 177.797C845.291 178.689 843.245 179.201 841.159 179.303C839.073 179.404 836.987 179.094 835.021 178.39C833.055 177.686 831.246 176.602 829.699 175.199C828.152 173.796 826.897 172.101 826.004 170.213C825.112 168.325 824.6 166.279 824.498 164.193C819.924 169.717 814.15 174.124 807.614 177.078C801.079 180.032 793.956 181.456 786.788 181.241C753.982 181.241 727.379 154.638 727.379 115.892C727.379 78.6969 752.431 50.8 786.788 50.8C793.858 50.5764 800.893 51.8927 807.405 54.6575C813.916 57.4223 819.749 61.5697 824.498 66.8123V15.1534C824.691 11.07 826.449 7.2175 829.407 4.39584C832.365 1.57419 836.295 0 840.383 0C844.471 0 848.402 1.57419 851.36 4.39584C854.318 7.2175 856.076 11.07 856.268 15.1534ZM824.498 115.892C824.498 96.0026 810.808 80.2483 791.954 80.2483C772.839 80.2483 758.891 96.0026 758.891 115.892C758.891 135.78 772.839 151.796 791.954 151.796C810.808 151.782 824.498 135.766 824.498 115.87V115.892Z"
      fill="currentColor"
    />
    <path
      d="M87.8217 9.7041C118.818 9.7041 145.941 23.9105 145.941 39.6683C145.941 49.2099 138.965 53.8748 130.96 53.8748C119.076 53.8748 110.552 41.7357 86.2738 41.7357C53.7263 41.7357 34.3534 66.0138 34.3534 95.978C34.3534 125.681 53.7263 149.694 86.2738 149.694C110.552 149.694 119.076 137.813 130.96 137.813C138.965 137.813 145.941 142.202 145.941 152.016C145.941 167.515 118.818 181.98 87.8217 181.98C33.0457 181.98 7.07866e-06 143.234 7.07866e-06 95.9675C-0.0176626 48.1923 33.0457 9.7041 87.8217 9.7041Z"
      fill="currentColor"
    />
    <path
      d="M71.519 111.609C80.2218 111.609 87.2768 104.554 87.2768 95.8511C87.2768 87.1483 80.2218 80.0933 71.519 80.0933C62.8162 80.0933 55.7612 87.1483 55.7612 95.8511C55.7612 104.554 62.8162 111.609 71.519 111.609Z"
      fill="currentColor"
    />
    <path
      d="M117.251 111.609C125.954 111.609 133.009 104.554 133.009 95.8511C133.009 87.1483 125.954 80.0933 117.251 80.0933C108.549 80.0933 101.494 87.1483 101.494 95.8511C101.494 104.554 108.549 111.609 117.251 111.609Z"
      fill="currentColor"
    />
  </svg>
);

export default Logo;
